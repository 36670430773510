import React from "react";
import Img from "gatsby-image";
import { css } from "@emotion/react";
import { graphql, StaticQuery } from "gatsby";

const ProfileImage = ({ version = 1 }) => (
  <StaticQuery
    query={graphql`
      query {
        founderImage: imageSharp(
          fluid: { originalName: { regex: "/linda_2/" } }
        ) {
          fluid(maxWidth: 500, maxHeight: 500, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        founderImageTwo: imageSharp(
          fluid: { originalName: { regex: "/linda_3/" } }
        ) {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        founderImageThree: imageSharp(
          fluid: { originalName: { regex: "/linda_1/" } }
        ) {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    `}
    render={(data) => (
      <div>
        <Img
          css={css`
            max-width: 250px;
            margin: 20px auto;
            border-radius: 50%;
            overflow: hidden;
          `}
          fluid={
            {
              1: data.founderImage.fluid,
              2: data.founderImageTwo.fluid,
              3: data.founderImageThree.fluid,
            }[version]
          }
          alt="Linda Bäumer"
        />
      </div>
    )}
  />
);

export default ProfileImage;
